var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import style from "./Header.module.css";
import style_layout from "../../styles/layout/grid.module.css";
import notification_without_red_dot from "../../assets/img/notification_icon_for_header.png";
import red_indicator from "../../assets/img/red_dot_for_notification_bell.png";
import Button_login_icon from "../../assets/img/Button_login_icon.svg";
import { Link } from "react-router-dom";
import { useAppDispatch } from "@redux/store";
import { setActive, setAuthModalOpenStatus, setModal, setRegModalOpenStatus } from "@redux/modalReducer";
import { connect } from "react-redux";
import { SearchBlock } from "../SearchBlock/SearchBlock";
import { getAuthUserData, logoutUser } from "@redux/authReducer";
import { ModalErrorMaterial } from "../Modal/modalErrorMaterial/modalErrorMaterial";
import GetCookie from "../../hooks/cookies/getCookie";
import LoadableMainLogo from "../LoadableImage/LoadableMainLogo/LoadableMainLogo";
import LoginBlock from "./helpers/LoginBlock";
import { PopupWithOverlay } from "@src/UIKit/PopupWithOverlay/Popup";
import { ModalAuth } from "../Modal/modalAuth/ModalAuth";
import { ModalReg } from "../Modal/modalReg/ModalReg";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { LANGUAGES } from "@src/constants";
import * as process from "process";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export var HeaderBlock = function (_a) {
    var _b, _c, _d, _e;
    var getAuthUserData = _a.getAuthUserData, menu = _a.menu, isAuthorized = _a.isAuthorized, userAuthInfo = _a.userAuthInfo, isMenuAndHeaderDataLoding = _a.isMenuAndHeaderDataLoding, isAuthModalOpen = _a.isAuthModalOpen, isRegModalOpen = _a.isRegModalOpen;
    var dispatch = useAppDispatch();
    var header = (_b = menu === null || menu === void 0 ? void 0 : menu[0]) === null || _b === void 0 ? void 0 : _b.header;
    var logo = (_c = menu === null || menu === void 0 ? void 0 : menu[0]) === null || _c === void 0 ? void 0 : _c.logo;
    var isNeedTranslationSelector = (_d = Boolean(process.env.NEED_TRANSLATION)) !== null && _d !== void 0 ? _d : false;
    var _f = useState(false), openLittleMenu = _f[0], setOpenLittleMenu = _f[1];
    var userHasNotification = useSelector(function (state) { return state.auth.userAuthInfo.notification; });
    var isLogoutAfterChangePassword = useSelector(function (state) { return state.auth.isLogoutAfterChangePassword; });
    var accessToken = GetCookie("access");
    var isUserAuth = GetCookie("isUserAuth") === "true";
    var isRefreshValid = ((_e = GetCookie("refresh")) === null || _e === void 0 ? void 0 : _e.length) > 0 && typeof GetCookie("refresh") === "string";
    useEffect(function () {
        if (!isRefreshValid && isUserAuth && !isLogoutAfterChangePassword) {
            dispatch(logoutUser());
        }
    }, [isRefreshValid, isUserAuth, isLogoutAfterChangePassword]);
    useEffect(function () {
        if (accessToken && isUserAuth)
            getAuthUserData();
    }, [accessToken, isUserAuth]);
    var setAuthModalOrCreatePostOpen = function () {
        if (isAuthorized) {
            dispatch(setModal("create-post-mini-modal"));
            dispatch(setActive(true));
        }
        else {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
        }
    };
    var handleCloseAuthModal = function () {
        dispatch(setAuthModalOpenStatus({ isOpen: false }));
    };
    var handleCloseRegModal = function () {
        dispatch(setRegModalOpenStatus({ isOpen: false }));
    };
    var handleChangeLanguage = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, i18n.changeLanguage(event.target.value)];
                case 1:
                    _a.sent();
                    window.location.reload();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsx("header", __assign({ className: style.header }, { children: _jsxs("div", __assign({ className: style.header_container }, { children: [_jsxs("div", __assign({ className: style.left_block }, { children: [_jsxs("div", __assign({ className: style.logo_block }, { children: [_jsx(Link, __assign({ className: style.logo_link, to: "/" }, { children: _jsx(LoadableMainLogo, { isLoading: isMenuAndHeaderDataLoding, src: logo }) })), _jsx("p", { dangerouslySetInnerHTML: { __html: header } })] })), _jsx(ModalErrorMaterial, {})] })), _jsxs("div", __assign({ className: style_layout.col_header_row }, { children: [_jsx(SearchBlock, {}), false ? (_jsx("select", __assign({ defaultValue: i18n.language === "ru" ? "ru" : "en", onChange: function (event) { return handleChangeLanguage(event); } }, { children: LANGUAGES.map(function (lang) {
                                    return _jsx("option", __assign({ value: lang.value }, { children: lang.name }));
                                }) }))) : null, _jsxs("div", __assign({ className: style.right_block }, { children: [_jsxs("div", __assign({ className: style.right_block_container }, { children: [userAuthInfo.role !== 3 ? (_jsx("button", __assign({ className: style.button_create, onClick: function () {
                                                    setAuthModalOrCreatePostOpen();
                                                } }, { children: _jsxs("div", __assign({ className: style.button_create_wrapper }, { children: [_jsx("img", { src: Button_login_icon }), _jsx("span", { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C" })] })) }))) : null, isUserAuth && (_jsx(Link, __assign({ className: style.notification_link, to: "notifications/" }, { children: _jsxs("div", __assign({ className: style.notificationWrapper }, { children: [_jsx("img", { className: style.notification, src: notification_without_red_dot, alt: "notification" }), userHasNotification && _jsx("img", { src: red_indicator, className: style.red_indicator })] })) }))), _jsx(LoginBlock, { isUserAuth: isUserAuth, isAvatarLoading: isMenuAndHeaderDataLoding, userAuthInfo: userAuthInfo, openLittleMenu: openLittleMenu, setOpenLittleMenu: setOpenLittleMenu })] })), _jsx(PopupWithOverlay, __assign({ onClose: handleCloseAuthModal, isOpened: isAuthModalOpen }, { children: _jsx(ModalAuth, { onClose: handleCloseAuthModal }) })), _jsx(PopupWithOverlay, __assign({ onClose: handleCloseRegModal, isOpened: isRegModalOpen }, { children: _jsx(ModalReg, { onClose: handleCloseRegModal }) }))] }))] }))] })) })) }));
};
var mapStateToDataHeader = function (state) {
    return {
        isAuthorized: state.auth.isAuth,
        isAuthModalOpen: state.modal.isAuthModalOpen,
        isRegModalOpen: state.modal.isRegModalOpen,
        isUserInfoLoading: state.auth.isUserInfoLoading,
        isMenuAndHeaderDataLoding: state.menu.isMenuAndHeaderDataLoding,
        userAuthInfo: state.auth.userAuthInfo,
        menu: state.menu.results,
        user: state.auth.user,
    };
};
var mapDispatchToHeader = function (dispatch) {
    return {
        getAuthUserData: function () { return dispatch(getAuthUserData()); },
    };
};
export var Header = connect(mapStateToDataHeader, mapDispatchToHeader)(HeaderBlock);
